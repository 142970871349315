
.sm {
    &__base {
        --wrapperSize: 100%;
        --reelSize: 32px;
        --displaySize: 96px;
        --displayZoom: 2;
        --shadowWeight: 1;

        position: relative;
        width: var(--wrapperSize);
        height: var(--wrapperSize);
        box-sizing: border-box;
        pointer-events: none;
        transform-origin: left center;
        will-change: transform;

        &.has-zoom {
            transform: scale(var(--displayZoom));
        }
    }

    &__reelsContainer {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__reel {
        --i: 0;
        --bg: transparent;

        position: absolute;
        top: calc(var(--index) * var(--reelSize));
        left: calc(var(--index) * var(--reelSize));
        width: calc(100% - var(--index) * 2 * var(--reelSize));
        height: calc(100% - var(--index) * 2 * var(--reelSize));
        background: var(--bg);
        border-radius: 100%;
        overflow: hidden;
        box-shadow: 0 0 calc(var(--shadowWeight) * var(--reelSize)) 0 rgb(0 0 0 / 12.5%);
        will-change: transform;

        &:first-child,
        &:last-child {
            box-shadow: none;
        }
    }

    &__cell {
        position: absolute;
        height: var(--reelSize);
        line-height: calc(var(--reelSize) * 0.9);
        font-size: calc(var(--reelSize) / 2);
        top: 50%;
        left: 0;
        width: 50%;
        margin-top: calc(var(--reelSize) / -2);
        transform-origin: center right;

        &--has-shadow {
            transition: opacity linear 0.125s;

            .is-stop & {
                opacity: 0 !important;
            }
        }

        &--has-blur {
            // Blur is too slow on Firefox. This could be fixed using (pre-blurred) images instead of icons.
            filter: blur(calc(var(--reelSize) / 8));
        }
    }

    &__figure {
        display: inline-block;
        text-align: center;
        width: var(--reelSize);
        height: 100%;
    }

    &__display {
        --displayShadowColor: rgb(0 0 0 / 12.5%);

        position: absolute;
        top: 50%;
        left: 0;
        width: var(--displaySize);
        height: var(--reelSize);
        transform: translate(0, -50%);
        transition: box-shadow linear 0.25s, background linear 0.25s;
        border-radius: 128px;
        box-shadow: 0 0 calc(var(--shadowWeight) * var(--reelSize)) 0 var(--displayShadowColor);
        mix-blend-mode: color;

        &.is-win {
            --displayShadowColor: rgb(127 160 0 / 50%);

            background: rgb(127 160 0);
        }

        &.is-fail {
            --displayShadowColor: rgb(255 0 0 / 25%);

            background: rgb(255 0 0);
        }
    }
}
