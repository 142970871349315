@import '../../styles/mixins/media-queries';
@import '../../styles/utils/focus';

.modal {
    &__root {
        position: fixed;
        top: 69px;
        bottom: 69px;
        left: 50%;
        width: 100%;
        background: #fff;
        font-size: 12px;
        transform: translate(-50%, 0);
        box-sizing: border-box;
        display: none;
        overflow: hidden auto;
        user-select: auto;
        padding-bottom: 64px;
        z-index: 100;

        &.is-open {
            display: block;
        }
    }

    &__title {
        // position: relative;
        font-size: 16px;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 10;
    }

    &__titleIcon {
        font-size: 24px;
        margin-right: 16px;
        width: 32px;
        display: inline-block;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        position: absolute;
        font-size: 16px;
        top: 0;
        right: 0;
        border: 0;
        background: transparent;
        outline: none;
        height: 48px;
        width: 48px;
        cursor: pointer;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-family: 'Press Start 2P', monospace;

        &Icon {
            transition: transform ease-in 0.125s;
        }

        &:hover > &Icon {
            transform: scale(1.4);
        }

        #{$focusVisible} &:focus::before {
            content: '';
            position: absolute;
            inset: 4px;
            border: 1px dotted black;
        }
    }
}
